var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('ValidationObserver',{ref:"addUserForm",attrs:{"slim":true}},[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Логин")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("E-mail")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Пароль")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Роли")]),_c('md-select',{attrs:{"multiple":""},model:{value:(_vm.rolesAdded),callback:function ($$v) {_vm.rolesAdded=$$v},expression:"rolesAdded"}},_vm._l((_vm.roles),function(roleOption){return _c('md-option',{key:("option_role_" + (roleOption.id)),attrs:{"value":roleOption.id}},[_vm._v(" "+_vm._s(roleOption.title)+" ")])}),1)],1)]}}])})],1),_c('div',{staticClass:"buttons-wrap justify-end"},[_c('md-button',{staticClass:"md-raised md-danger",on:{"click":function($event){return _vm.$router.push('/admins/list')}}},[_vm._v(" Отменить ")]),_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.addNewUser}},[_vm._v(" Создать ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }