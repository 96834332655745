<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addUserForm" :slim="true">
          <md-card-content>
            <div class="md-layout md-gutter">
              <ValidationProvider
                v-slot="{ errors, reset }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Логин</label>
                  <md-input v-model="login" @focus="reset" />
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors, reset }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>E-mail</label>
                  <md-input v-model="email" @focus="reset" />
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors, reset }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Пароль</label>
                  <md-input v-model="password" @focus="reset" />
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Роли</label>
                  <md-select v-model="rolesAdded" multiple>
                    <md-option
                      v-for="roleOption in roles"
                      :key="`option_role_${roleOption.id}`"
                      :value="roleOption.id"
                    >
                      {{ roleOption.title }}
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="buttons-wrap justify-end">
              <md-button
                class="md-raised md-danger"
                @click="$router.push('/admins/list')"
              >
                Отменить
              </md-button>
              <md-button class="md-raised md-success" @click="addNewUser">
                Создать
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: "",
      email: "",
      password: "",
      rolesAdded: [],
      roles: [
        {
          id: "user",
          title: "администратор пользователей",
        },
        {
          id: "support",
          title: "администратор поддержки",
        },
        {
          id: "words",
          title: "администратор запрещенных слов",
        },
      ],
    };
  },

  methods: {
    async addNewUser() {
      try {
        this.$notify({
          message: "Администратор добавлен",
          icon: "task_alt",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "primary",
        });
        this.$router.push("/admins");
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}
</style>
